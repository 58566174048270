.expeditions-body{
    padding: 30px;
}
.expeditions-head {
    font-family: 'Kaushan Script', cursive;
    margin: 20px 0;
    text-align: center;
    font-size: 2.2em;
    transition: .5s;
}
.exp-head-dark{
    color: #9CB0A6;
    transition: 2s;
}
.list{
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.list-item{
    box-shadow:  13px 13px 26px #e8e8e8,
                 13px 13px 26px #e8e8e8;
    margin: 20px 0;
    width: 90vw;
    height: auto;
    border-radius: 15px;
    transition: .5s;
}
.list-item-dark{
    margin: 20px 0;
    width: 90vw;
    height: auto;
    border-radius: 15px;
    background: #2f3130;
    transition: 0.5s;
}

.list-item img, .list-item-dark img{
    width: 100%;
    height: 250px;
    cursor: pointer;
    object-fit: cover;
    border-radius: 15px 15px 0 0;
    margin-bottom: 15px;
}

.item-description{ 
    border-radius: 0 0 15px 15px;
    color: rgb(0, 0, 0);
    padding: 10px 15px;
    margin: 30px 0;
}
.item-title{
    font-size: 1.5em;
    font-weight: 700;
    margin: 0 0 -5px 5px;
    text-decoration: none;
    color: #2F3130;
}
.item-title-dark{
    color: #fff;
    transition: 2s;
}
.item-details{
    font-size: 0.8em;
    margin: -50px 0 0 20px;
    color: white;
}
.body-dark{
    background-color: black;
    color: white;
    transition: .5s;
}
@media (min-width: 1000px) {
    .list{
        flex-direction: row;
        gap: 30px;
        flex-wrap: wrap;
    }
    .list-item, .list-item-dark{
        width: 450px;
        height: auto;
        border-radius: 15px;
    }
    .item-details{
        font-size: 1em;
    }
    .expeditions-head {
        font-size: 2.5em;
    }
}