.bannerBody{
    position: relative;
    width: 100%;
    height: calc(100vh - 80px);
}

.bannerBody img{
    width: 100%;
    height: calc(100vh - 80px);
    object-fit: cover;
}
.overlay {
    background: #0000005e;
    display: flex;
    position: absolute;
    width: 100%;
    height: inherit;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.call-to-action {
    margin: 0 0 0 5vw;
    text-align: left;
    color: white;
}
.call-to-action h2{
    font-family: 'Kaushan Script', cursive;
    color: #9CB0A6;
    margin-bottom: -20px;
    font-size: 2em;
}
.call-to-action h1{
    text-transform: uppercase;
    font-size: 2.5em;
}
.call-to-action button{
    font-size: 1.2em;
    border: solid 1px #9CB0A6;
    border-radius: 5px;
    background-color: #9CB0A6;
    color: #000;
    cursor: pointer;
}
.arrow-icon {
    vertical-align: middle;
}
.call-to-action p{
    font-size: 2em;
}
.call-to-action a{
    color: #000;
    text-decoration: none;
    display: inline-block;
    padding: 10px 20px;
}