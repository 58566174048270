.mytrip-body{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    min-height: 100vh;
}
.mytrip-body-dark{
    background-color: black;
}
.profile-info{
    box-shadow:  -13px -13px 26px #e8e8e8,
                 13px 13px 26px #e8e8e8;
    margin: 20px 0;
    width: 90vw;
    height: auto;
    border-radius: 15px;
    transition: .5s;
}
.profile-info-dark{
    background-color: #2f3130;
    box-shadow: none;
    color: white;
}

.picture-name-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px
}
.picture-name-container img{
    width: 150px;
    height: auto;
    border-radius: 100px;
}
.name-info{
    text-align: center;
}
.name-info h1{
    font-size: 1.8em;
    margin-bottom: -5px;
}
.traveler-tag{
    background-color: #A0B0A6;
    color: black;
    font-size: 0.8em;
    font-family: sans-serif;
    padding: 5px 10px;
    border-radius: 5px;
}

.saved-expeditions-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
}
.saved-expeditions-item{
    box-shadow:  -13px -13px 26px #e8e8e8,
                 13px 13px 26px #e8e8e8;
    height: auto;
    border-radius: 15px;
    font-size: 1.2em;
    transition: .5s;
    width: 85vw;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
}
.saved-expeditions-item img{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
}

.saved-exp-link{
    text-decoration: none;
    color: black;
    margin-left: 10px;
    cursor: pointer;
    display: block;
}
.saved-exp-link-dark{
    color: white;
}
.saved-expeditions-item-dark{
    box-shadow: none;
    background-color: #2f3130;
    transition: .5s;
    color: white;
}
.saved-exp-btn-container{
    display: flex;
    flex-direction: row;
}
.btn-openmaps{
    border: solid 1px black;
    background-color: white;
    display: block;
    border-radius: 10px;
    margin: 10px 0 0 10px;
}
.btn-openmaps-dark{
    background-color: #A0B0A6;
    border-color: #A0B0A6;
}
.btn-openmaps a{
    text-decoration: none;
    display: inline-block;
    padding: 10px;
    font-family: sans-serif;
    color: black;
}
.btn-unsave-exp{
    border: solid 1px #af0a11;
    background-color: #af0a11;
    display: block;
    border-radius: 10px;
    padding: 0 10px;
    margin: 10px 0 0 10px;
    color: white;
    font-size: 1.1em;
    line-height: 5px;
    cursor: pointer
}
@media (min-width: 1000px) {
    .mytrip-body{
        flex-direction: row;
        align-items: flex-start;
        gap: 60px;
        justify-content: center;
    }
    .picture-name-container{
        flex-direction: row;
    }
    .name-info{
        text-align: unset;
    }
    .profile-info{
        width: 50vw;
    }
    .saved-expeditions-item{
        width: 30vw;
    }
    .name-info{
        margin-left: 20px;
    }
    .btn-openmaps{
        margin: 10px 0 0 20px;
    }
    .saved-exp-link{
        margin-left: 20px;
    }
}