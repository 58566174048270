button.switch {
    position: fixed;
    right: 0px;
    bottom: 20px;
    font-size: 30px;
    padding: 10px 30px 0px 10px;
    border: solid 1px black;
    background-color: #fff;
    cursor: pointer;
    border-radius: 15px 0 0 15px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.233);
    color: black;
    transition: .4s;
    z-index: 1;
    transition: .4s;
}

button.switch-dark {
    background-color: #2F3130 !important;
    color: yellow !important;
    transition: .4s;
}