.title-banner{
    width: 100%;
    height: 300px;
    position: relative;
    text-align: center;
}
.title-banner img{
    width: 100%;
    height: 300px;
    cursor: pointer;
    object-fit: cover;
    margin-bottom: 15px;
}
.title-banner h1 {
    position: absolute;
    top: 100px;
    width: 100%;
    text-align: center;
    font-size: 2.5em;
    color: #fff;
}
.picture-detail-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    gap: 30px;
}
.picture{
    position: relative;
}
.picture img{
    width: 90vw;
    border-radius: 15px;
}
.timeperiod {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: black;
    background-color: white;
    padding: 0 15px;
    border-radius: 10px;
    font-size: 0.8em;
}
.detail-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.detail-item{
    box-shadow:  13px 13px 26px #e8e8e8,
                 13px 13px 26px #e8e8e8;
    width: 80vw;
    height: auto;
    padding: 5px 20px;
    border-radius: 15px;
    font-size: 1.2em;
    transition: .5s;
}
.detail-item-dark{
    box-shadow: none;
    background-color: #2f3130;
    transition: .5s;
}
.detail-item-title{
    font-size: 0.6em;
    margin-bottom: -10px;
    font-weight: bold;
}

.book-at-guide{
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.205) !important;
    width: 100%;
    height: auto;
    border-radius: 15px;
    font-size: 1.2em;
    border: solid 1px #A0B0A6;
    background-color: #A0B0A6;
    text-align: left;
    transition: all 0.3s ease 0s;
}
.book-at-guide:hover {
    background-color: #b0c0b6;
    box-shadow: 0px 15px 20px rgba(46, 229, 156, 0.568);
    color: #fff;
    transform: translateY(-7px);
  }
.book-at-guide a{
    text-decoration: none;
    color: #2F3130;
    display: block;
    padding: 20px 20px;
}
.yt-iframe{
    width: 40vw;
    height: 400px;
    border-radius: 15px;
}
.description-item{
    box-shadow:  13px 13px 26px #e8e8e8,
                 13px 13px 26px #e8e8e8;
    width: 50vw;
    height: 200px;
    padding: 5px 20px;
    border-radius: 15px;
    font-size: 1.2em;
}
.btn-save {
    position: absolute;
    top: 20px;
    left: 20px;
    color: black;
    background-color: #ffffff;
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 0.8em;
    font-weight: 500;
    border: solid 1px white;
    cursor: pointer;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.205) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.saved{
    background-color: #A0B0A6;
    border: solid 1px #A0B0A6;
}
.heart-icon {
    vertical-align: -1px;
    font-size: 25px;
}

@media (min-width: 1000px) {
    .picture-detail-container{
        flex-direction: row;
        align-items: flex-start;
        padding: 30px;
        gap: 30px;
    }
    .picture-detail-container img{
        width: 60vw;
        border-radius: 15px;
    }
    .detail-item{
        width: 20vw;
    }
}