.forecast-today {
    border-bottom: solid 1px black;
    margin-bottom: 5px;
}
.temperature-now {
    font-size: 1.8em;
    font-weight: 800;
    margin: 30px 0 0 0;
}
.phrase-today{
    font-size: 0.8em;
    margin-top: 5px;
}
.upcoming-days img {
    width: auto;
    height: 30px;
    vertical-align: middle;
}

table {
    width: 100%;
}
  
td {
    text-align: left;
    font-size: 0.8em;
}
  
.td-day{
      width: 100px;
}
.td-icon{
    width: 50px;
}
.td-temp{
    text-align: right;
}