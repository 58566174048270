/*Desktop View*/
.header {
    display: flex;
    background-color: #2F3130;
    justify-content: space-between;
    align-items: center;
    padding: 0px 40px;
    height: 80px;
    font-family: 'Lora';
  }
  .header a {
    color: #DBDEDB;
    text-decoration: none;
  }
  .logo-nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #1A1C1B;
    padding: 10px 15px;
  }
  .logo-container a{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: 'Kaushan Script', cursive;
    font-size: 1.2rem;
  }
  .logo-container img {
    width: auto;
    height: 40px;
  }
  .nav-options {
    padding-left: 25px;
    display: flex;
    list-style-type: none;
  }
  .nav-options li{
    margin-left: 25px;
  }
  .mobile-option {
    display: none;
  }
  
  .option :hover {
    color: white;
  }
  .signin {
    padding: 0px 5px;
    list-style-type: none;
    position: absolute;
    right: 40px;
  }
  .signin-btn {
    padding: 10px 10px;
    height: 2.4rem;
    border-radius: 3px;
    background: #9CB0A6;
    color: #2F3130 !important;
    font-weight: 600;
    cursor: pointer;
    align-items: center;
    transition: .5s;
  }
  .signin-btn:hover {
    padding: 15px 15px;
    transition: .5s;
  }
  .mobile-menu {
    display: none;
  }
  @media (max-width: 800px) {
    /*Mobile View */
    .header {
      padding: 0px 10px;
    }
    .logo {
      width: 45px;
      height: 45px;
    }
    .nav-options {
      display: flex;
      width: 100%;
      height: calc(100vh - 64px);
      position: fixed;
      top: 64px;
      left: -100%;
      opacity: 0;
      transition: all 0.5s ease;
      flex-direction: column;
      list-style-type: none;
      grid-gap: 0px;
    }
    .nav-options li{
      margin: 0;
    }
    .nav-options.active {
      background: #1A1C1B;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      align-content: center;
      padding-left: 0px;
    }
  
    .menu-icon {
      width: 45px;
      height: 45px;
      color: #9CB0A6;
    }
    .option {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 10vw;
      padding: 30px 0px;
    }

  
    .signin {
      display: none;
    }
  }
  .mobile-menu {
    display: block;
  }